import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

type TInitialState = {
  error?: string;
  success?: string;
  isLoading?: boolean;
};

const initialState: TInitialState = {};

const reducer = createSlice({
  name: "agrupamento",
  initialState,
  reducers: {
    agrupamentosError(state, { payload }: PayloadAction<string>) {
      state.error = payload;
    },
    agrupamentoRequest(state, { payload }: PayloadAction<string>) {
      state.isLoading = true;
      state.success = "";
      state.error = "";
    },
    agrupamentoSuccess(state) {
      state.isLoading = false;
      state.success = "";
      state.error = "";
    },
  },
});

export const agrupamentoActions = reducer.actions;

export default reducer.reducer;
