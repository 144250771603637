import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../modules/app/mainReducer";
import { arrayActions } from "../modules/array/reducer";

export function useGetArrays() {
  const dispatch = useDispatch();
  
  const networkOnline = useSelector((state: RootState) => state.login?.networkOnline);
  const arrayUpdatedAt = useSelector((state: RootState) => state.array?.arrays_updated_at);
  const arrays = useSelector((state: RootState) => state.array.arrays ?? {});

  const lastUpdateTime = (arrayUpdatedAt ? new Date(arrayUpdatedAt) : new Date()).getTime();
  const currentTime = new Date().getTime();
  const minutesPassed = (currentTime - lastUpdateTime) / (1000 * 60);

  if ((minutesPassed > 60 && networkOnline) || (Object.keys(arrays).length === 0 && networkOnline)) {
    dispatch(arrayActions.arraysRequest());
  }
}
