import { PayloadAction } from "@reduxjs/toolkit";
import { apiCall } from "../../utils/apiCall";
import { put, all, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { call } from "redux-saga/effects";
import { formatError } from "../../utils/formatError";
import { agrupamentoActions } from "./reducer";
import { cargaActions } from "../carga/reducer";
import { Carga } from "../carga/types";
import { store } from "../app/mainReducer";


function* agrupamentoWorker({ payload }: PayloadAction<string>) {
  try {
    const res: AxiosResponse<Carga[]> = yield call(apiCall, {
      url: `/gestao-de-entrega/v2/agrupamentos/${payload}`,
      method: "get",
    });

    let cargasSemSerDoAgrupamentoAtual = (store.getState().carga?.cargas ?? []).filter((carga) => carga.n01_ukey != payload);
    yield put(cargaActions.cargasRequestSuccess([
      ...cargasSemSerDoAgrupamentoAtual,
      ...res.data,
    ]));
    yield put(agrupamentoActions.agrupamentoSuccess());
  } catch (error) {
    yield put(
      agrupamentoActions.agrupamentosError(formatError(error))
    );
  }
}

export function* agrupamentoSaga() {
  yield all([
    takeLatest("agrupamento/agrupamentoRequest", agrupamentoWorker),
  ]);
}
