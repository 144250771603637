import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Input,
  Skeleton,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Error, Layout } from "../../../components";
import { useIsAuth } from "../../../hooks/useIsAuth";
import { RootState } from "../../app/mainReducer";
import { useEffect, useState } from "react";
import { cargaActions } from "../reducer";
import { Carga, CargaTransbordo, InfoSemCarga } from "../components";
import { Link, useNavigate } from "react-router-dom";
import { CargaItem, Carga as CargaType } from "../types";
import { imagensRejeitadasActions } from "../../imagens-rejeitadas/reducer";
import config from "../../../utils/config";

const CargaList = () => {
  useIsAuth();
  const isLoading = useSelector((state: RootState) => state.carga.isLoading);
  const cargas = useSelector((state: RootState) => state.carga.cargas);
  const imagensRejeitadas = useSelector(
    (state: RootState) => state.imagensRejeitadas.imagensRejeitadas ?? []
  );
  const error = useSelector((state: RootState) => state.carga.error || "");
  const networkOnline = useSelector(
    (state: RootState) => state.login.networkOnline || false
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (networkOnline) {
      dispatch(imagensRejeitadasActions.imagensRejeitadasRequest());
      dispatch(cargaActions.cargasRequest());
    }
  }, [dispatch, cargaActions]);

  const eTransbordo = useSelector((state: RootState) => state.login?.eTransbordo);

  const nagivate = useNavigate();

  useEffect(() => {
    if (eTransbordo) {
      nagivate('/transbordos');
    }
  }, []);

  const search = () => {
    if (networkOnline) {
      dispatch(cargaActions.cargasRequest());
      dispatch(imagensRejeitadasActions.imagensRejeitadasRequest());
    } else {
      alert("Você está sem internet, não foi possível buscar os dados.");
    }
  };

  const [filtro, setFiltro] = useState("");

  const getCargasFiltradas = (): CargaType[] => {
    if (!cargas) return [];

    if (!filtro.trim()) return cargas;

    return cargas.filter(
      (i) =>
        i.itens.filter((j) => j.nota_numero.includes(filtro.trim())).length > 0
    );
  };

  const getCargasAgrupadasPorN01 = (): CargaType[] => {
    const cargas = getCargasFiltradas().filter((carga) => !! carga.n01_ukey);
    const cargasAgrupadas: { [key: string]: CargaType[] } = {};

    cargas.forEach(carga => {
      const n01Key = carga.n01_ukey;
      if (!cargasAgrupadas[n01Key]) {
        cargasAgrupadas[n01Key] = [];
      }
      cargasAgrupadas[n01Key].push(carga);
    });

    return Object.values(cargasAgrupadas).map(grupo => {
      let itens: CargaItem[] = [];

      let agrupamentos: string[] = [];

      for (const carga of grupo) {
        for (const item of carga.itens) {
          itens.push(item);
        }

        if (! agrupamentos.includes(carga.numero)) {
          agrupamentos.push(carga.numero);
        }
      }

      return {
        ...grupo[0],
        qt_pedidos: String(grupo.reduce((prev, curr) => {
          return prev + parseInt(curr.qt_pedidos); 
        }, 0)),
        itens,
        _agrupamento_numeros: agrupamentos.join(', '),
      }
    });
  };

  const quantidadeFotosRejeitadas = imagensRejeitadas.reduce(
    (soma, registroAtual) => {
      let somaAtual = soma;
      if (registroAtual.gestao_imagem1_rejeitada == 1) {
        somaAtual++;
      }
      if (registroAtual.gestao_imagem2_rejeitada == 1) {
        somaAtual++;
      }
      return somaAtual;
    },
    0
  );

  const buscarCargas = () => {
    if (config.agrupaCargasPeloAgrupamento) {
      return getCargasAgrupadasPorN01();
    }

    return getCargasFiltradas();
  }

  return (
    <Layout canLogout={true} isLoading={isLoading} onSearch={search}>
      {quantidadeFotosRejeitadas > 0 && (
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          width={"full"}
          textAlign={"center"}
          mt={2}
        >
          Você possui {quantidadeFotosRejeitadas} fotos rejeitadas pela empresa.
          <Link to={"/imagens-rejeitadas"}>
            <Button colorScheme="blue">Clique aqui para reenviá-las</Button>
          </Link>
        </Flex>
      )}
      <Center mt={4}>
        <Heading>Suas cargas em aberto</Heading>
      </Center>
      <Center mb={2}>
        <Heading size="sm">Selecione uma carga (clique)</Heading>
      </Center>
      <Flex mb={4} width={"full"} wrap="wrap">
        <Input
          borderRadius={0}
          bg="white"
          placeholder="Busque aqui pelo número da nota"
          onChange={(evt) => setFiltro(evt.target.value)}
        />
      </Flex>
      <Error error={error} />
      {isLoading &&
        [1, 2, 3, 4, 5, 6].map((i) => (
          <Skeleton height="90px" mb={1} key={i} />
        ))}
      {!isLoading &&
        buscarCargas().map((carga) => (
          <div key={carga.j14_ukey}>
            {!carga.e_transbordo && (
              <Carga
                onClick={() => navigate(config.agrupaCargasPeloAgrupamento ? `/agrupamentos/${carga.n01_ukey}` : `/cargas/${carga.j14_ukey}`)}
                carga={carga}
              />
            )}
            {carga.e_transbordo && <CargaTransbordo carga={carga} />}
          </div>
        ))}
      {!isLoading && buscarCargas().length === 0 && <InfoSemCarga />}
    </Layout>
  );
};

export default CargaList;
