import { Box, Flex, Heading, Text, useMediaQuery } from "@chakra-ui/react";
import { formatDate } from "../../../utils/formatDate";
import { Carga as CargaType } from "../types";
import { FiArrowLeft } from "react-icons/fi";
import config from "../../../utils/config";
import React from "react";

interface IProps {
  carga: CargaType;
  onClick: () => void;
}

const Carga = ({ carga, onClick }: IProps) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')

  const buscaTitulo = () => {
    if (config.agrupaCargasPeloAgrupamento) {
      return `Agrupamento ${carga.agrupamento_numero} - ${formatDate(carga.agrupamento_data_emissao)}`;
    }

    return `Carga ${carga.numero} - ${formatDate(carga.data_emissao)}`
  }

  const mostraTotalDeEntregas = ! config.agrupaCargasPeloAgrupamento;

  const mostraNumeroDasCargas = config.agrupaCargasPeloAgrupamento;

  return (
    <Box onClick={onClick} _hover={{ backgroundColor: '#f7f7f7' }} position={"relative"} cursor="pointer" shadow="md" bg="white" mb={1}>
      <Flex justifyContent="space-between">
        <Box p={3}>
          <Heading size="md"> {buscaTitulo()} </Heading>
          <Text>{mostraTotalDeEntregas && <React.Fragment> Total entregas: {carga.qt_pedidos} | </React.Fragment>} {mostraNumeroDasCargas && <React.Fragment> Carga(s): {carga._agrupamento_numeros} | </React.Fragment>} Em aberto: {carga.itens.length}</Text>
        </Box>
        {isMobile && <Flex position={"absolute"} top={6} right={2} fontWeight={700} fontSize={isMobile ? "16px" :"22px"} my={"auto"} alignItems={"center"}>
         <FiArrowLeft />&nbsp;Clique aqui
        </Flex>}
      </Flex>
    </Box>
  )
}


export default Carga;