import { Box, Center, Flex, Heading, SimpleGrid, Skeleton } from "@chakra-ui/react";
import { ButtonBack, Layout } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { imagensRejeitadasActions } from "../reducer";
import { useEffect } from "react";
import { formatCep } from "../../../utils/formatCep";
import { ReenviarImagemForm } from "../components/ReenviarImagemForm";

export const ImagensRejeitadas = () => {
  const isLoading = useSelector(
    (state: RootState) => state.imagensRejeitadas?.isLoading
  );
  const success = useSelector(
    (state: RootState) => state.imagensRejeitadas?.success
  );
  const networkOnline = useSelector(
    (state: RootState) => state.login?.networkOnline
  );
  const imagensRejeitadas = useSelector(
    (state: RootState) => state.imagensRejeitadas?.imagensRejeitadas ?? []
  );
  const eTransbordo = useSelector(
    (state: RootState) => state.login?.eTransbordo
  );
  const dispatch = useDispatch();

  const search = () => {
    if (!networkOnline) {
      dispatch(
        imagensRejeitadasActions.error("É necessário ligar a internet.")
      );
      return;
    }
    if (eTransbordo) {
      dispatch(imagensRejeitadasActions.imagensRejeitadasTransbordoRequest());
      return;
    }
    dispatch(imagensRejeitadasActions.imagensRejeitadasRequest());
  };

  useEffect(() => {
    search();
  }, []);

  useEffect(() => {
    if (!!success) {
      search();
    }
  }, [success]);

  return (
    <Layout
      showFooter={!eTransbordo}
      canLogout={true}
      isLoading={isLoading}
      onSearch={search}
      title={<ButtonBack to={eTransbordo ? '/transbordos' : '/cargas'} />}
      backTo={'/cargas'}
    >
      <Center my={4} textAlign={"center"}>
        <Heading size={"md"}>
          Suas fotos enviadas nas entregas que foram rejeitadas pela empresa
        </Heading>
      </Center>

      {isLoading &&
        [1, 2, 3, 4, 5, 6].map((i) => (
          <Skeleton height="90px" mb={1} key={i} />
      ))}

      {!isLoading && imagensRejeitadas.map((imagemRejeitada) => (
        <Box
          _hover={{ backgroundColor: "#f7f7f7" }}
          shadow="md"
          bg="white"
          mb={1}
        >
          <Flex justifyContent="space-between">
            <Box width="full" p={3}>
              <Heading size="md">
                Nota Fiscal: {imagemRejeitada.nota_numero}
              </Heading>
              <SimpleGrid columns={1} fontSize="0.9rem">
                <Box px={1}>
                  <small>Fantasia: {imagemRejeitada.cliente_fantasia}</small>{" "}
                  <br />
                  <small>
                    Cidade: {imagemRejeitada.cliente_cidade} (
                    {formatCep(imagemRejeitada.cliente_cep)})
                  </small>{" "}
                  <br />
                  <small>
                    Endereço: {imagemRejeitada.cliente_endereco}, n°{" "}
                    {imagemRejeitada.cliente_numero} -{" "}
                    {imagemRejeitada.cliente_bairro}
                  </small>{" "}
                  <br />
                </Box>
              </SimpleGrid>

              {imagemRejeitada.gestao_imagem1_rejeitada == 1 && (
                <ReenviarImagemForm
                  fotoAtual={imagemRejeitada.gestao_url_imagem1}
                  imagemRejeitada={imagemRejeitada}
                  dispatchForm={(values) =>
                    dispatch(
                      imagensRejeitadasActions.recolocarImagem1Request(values)
                    )
                  }
                  label="Foto 1 rejeitada"
                />
              )}
              {imagemRejeitada.gestao_imagem2_rejeitada == 1 && (
                <ReenviarImagemForm
                  fotoAtual={imagemRejeitada.gestao_url_imagem2}
                  imagemRejeitada={imagemRejeitada}
                  dispatchForm={(values) =>
                    dispatch(
                      imagensRejeitadasActions.recolocarImagem2Request(values)
                    )
                  }
                  label="Foto 2 rejeitada"
                />
              )}
            </Box>
          </Flex>
        </Box>
      ))}
    </Layout>
  );
};
