import { Center, Flex, FormLabel, Heading, Input, Skeleton } from "@chakra-ui/react";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonBack, Error, Layout } from "../../../components";
import { RootState } from "../../app/mainReducer";
import { CargaFinalizada, CargaItem } from "../../carga/components";
import { agrupamentoActions } from "../reducer";
import { Carga, CargaItem as CargaItemType } from "../../carga/types";
import { cargaActions } from "../../carga/reducer";

const AgrupamentoItemList = () => {
  const isLoading = useSelector((state: RootState) => state.agrupamento.isLoading);
  const cargas = useSelector((state: RootState) => state.carga.cargas);
  const error = useSelector((state: RootState) => state.agrupamento.error || '');
  const networkOnline = useSelector((state: RootState) => state.login.networkOnline || false);

  const { ukey } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cargasDoAgrupamento = (cargas ?? []).filter((carga) => carga.n01_ukey == ukey);

  const gestao_entrega_pendentes = useSelector((state: RootState) => state.entrega.gestao_entrega_pendentes);

  useEffect(() => {
    if (!ukey) return;

    const cargaEncontrada = cargasDoAgrupamento.length > 0;

    if (cargaEncontrada) {
      return;
    }

    if (gestao_entrega_pendentes.length) {
      return;
    }

    if (networkOnline) {
      dispatch(agrupamentoActions.agrupamentoRequest(ukey));
    } else {
      dispatch(agrupamentoActions.agrupamentosError('Não é possível buscar as entregas, você está sem internet.'));
    }
  }, [dispatch, agrupamentoActions, cargas, ukey])

  const search = () => {
    if (networkOnline) {
      if (!ukey) return;

      dispatch(agrupamentoActions.agrupamentoRequest(ukey));
    }
  }
  const [filtro, setFiltro] = useState("");

  const itensDoAgrupamento = () => {
    let itens = [];

    for (const carga of cargasDoAgrupamento) {
      for (const item of carga.itens) {
        itens.push(item);
      }
    }

    return itens;
  }

  const getItens = () => {
    if (!itensDoAgrupamento().length) {
      return [];
    };

    if (!filtro) {
      return itensDoAgrupamento();
    };

    return itensDoAgrupamento().filter((i) => {
      return i.cliente_fantasia.toLowerCase().includes(filtro.toLowerCase()) ||
        i.cliente_razao_social.toLowerCase().includes(filtro.toLowerCase()) ||
        i.nota_numero.toLowerCase().includes(filtro.toLowerCase())
    });
  }

  const selecionaCargaERedireciona = (cargaItem: CargaItemType) => {
    dispatch(cargaActions.cargaRequestSuccess(cargasDoAgrupamento.find((carga) => carga.j14_ukey == cargaItem.j14_ukey) as Carga))

    navigate(`/entrega/nota/${cargaItem.j14_ukey}/${cargaItem.j10_ukey}/opcoes`)
  }

  return (
    <Layout showFooter={true} backTo="/cargas" isLoading={isLoading} onSearch={search} title={<ButtonBack to="/cargas" />}>
      <>
        <Center mt={1} mb={2}>
          <Heading>Notas do agrupamento {(cargasDoAgrupamento ?? [])[0]?.agrupamento_numero}</Heading>
        </Center>

        <Error error={error} />
        {(getItens().length > 0 || (getItens().length == 0 && filtro)) &&
          <Flex width="full" wrap="wrap">
            <FormLabel mx={2}>Filtrar pelo número da Nota ou Cliente</FormLabel>
            <Input mx={2} mb={4} bg="white" placeholder="Digite o número da nota ou nome do cliente" value={filtro} onChange={(e) => setFiltro(e.target.value)} />
          </Flex>
        }
        {
          isLoading && [1, 2, 3, 4, 5, 6].map((i) => (
            <Skeleton height='90px' mb={1} key={i} />
          ))
        }
        {
          !isLoading && getItens().map((carga_item) => (
            <CargaItem onClick={() => selecionaCargaERedireciona(carga_item)} carga_item={carga_item} key={carga_item.nota_numero} />
          ))
        }

        {getItens().length === 0 && !isLoading && !filtro &&
          <CargaFinalizada />
        }
      </>
    </Layout>
  )
}

export default AgrupamentoItemList;