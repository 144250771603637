import {
  Box,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react'
import { FiInfo, FiList } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../app/mainReducer';

const CargaFinalizada = () => {
  const navigate = useNavigate();
  const usuarioLogado = useSelector((state: RootState) => state.login?.parceiro);
  const { codigo_agrupamento } = useParams();

  return (
    <Box px={2}>
      <Box bg="green.500" p={3} borderRadius={5}>
        <Flex align="center">
          <FiInfo fontSize="3em" color="white" />
          <Flex ml={5} direction="column">
            <Text fontSize="lg" fontWeight="bold" color="white">
              Olá{usuarioLogado ? ` ${usuarioLogado?.razao_social}` : ` `}, esta carga foi finalizada.
            </Text>
          </Flex>
        </Flex>
      </Box>

      {usuarioLogado?.cpf_cnpj && !codigo_agrupamento &&
        <Flex width="full" justifyContent="center">
          <Button mt={3} mx="auto" width="full" colorScheme="blue" onClick={() => navigate('/')}>
            <FiList fontSize="1.5em" /> <Text ml={2}>SUAS CARGAS</Text>
          </Button>
        </Flex >
      }
    </Box>
  )
}

export default CargaFinalizada;
