import { Flex, Radio, Stack } from "@chakra-ui/react"
import { Form, Formik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { ButtonBack, InputField, Layout, SelectField } from "../../../components"
import { RootState } from "../../app/mainReducer"
import { AcaoHeader, BotaoFinalizarEntrega, Images, ModalObservacoes, Temporizador } from "../components"
import { getMotivosNaoEntrega } from "../utils"
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import { entregaActions } from "../reducer"
import RadioField from "../../../components/RadioField"
import { Carga, CargaItem } from "../../carga/types"
import { buscaCoordenadasAtuais } from "../../../utils/geolocalizacao"
import config from "../../../utils/config"

const RegistrarDevolucao = () => {
  const gestao_entrega_temporario = useSelector((state: RootState) => state.entrega.gestao_entrega_temporario);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ukey, tipo, j10_ukey } = useParams();
  const e_transbordo = tipo === 'transbordo';

  const carga = useSelector((state: RootState) =>
    (state.carga.carga || state.carga.cargas?.find((i) => i.j14_ukey === ukey))
  );

  const nota = useSelector((state: RootState) => carga?.itens?.find((i: CargaItem) => i.j10_ukey === j10_ukey));

  const [rotaDirecionar, setRotaDirecionar] = useState<string | null>(null);

  useEffect(() => {
    if (nota?.nota_dados_complementares_entrega_link_app) {
      const parsedUrl = new URL(nota?.nota_dados_complementares_entrega_link_app);
      setRotaDirecionar(parsedUrl.pathname);
    } else {
      setRotaDirecionar(null);
    }
  }, [])

  useEffect(() => {
    if (!gestao_entrega_temporario) {
      if (e_transbordo) {
        navigate(`/`)
        return;
      }

      if (rotaDirecionar) {
        navigate(rotaDirecionar);
        return;
      }

      if (config.agrupaCargasPeloAgrupamento && carga?.n01_ukey) {
        navigate(`/agrupamentos/${carga?.n01_ukey}`);
        return;  
      }

      navigate(`/cargas/${ukey}`)
    }
  }, [navigate, gestao_entrega_temporario]);

  const [openModalObservacoes, setOpenModalObservacoes] = useState(false);
  const [openedModalObservacoes, setOpenedModalObservacoes] = useState(false);

  return (
    <Layout showFooter={true} isLoading={false} title={
      <>
        <ButtonBack />
        <Temporizador />
      </>
    }>
      <AcaoHeader label="Registrar Devolução" />

      <Flex px={4} width="full" direction="column">
        {gestao_entrega_temporario && <Formik
          enableReinitialize
          initialValues={gestao_entrega_temporario}
          onSubmit={async (val, { setErrors }) => {
            /*if (!val.nome_recebedor) {
              setErrors({ nome_recebedor: 'Campo obrigatório' })
              return;
            }*/
            if (parseInt(val.motivo_devolucao) <= 1) {
              setErrors({ motivo_devolucao: 'Campo obrigatório' })
              return;
            }
            if (!val.url_imagem1) {
              setErrors({ url_imagem1: 'Tire a foto do canhoto!' })
              return;
            }

            if (!openedModalObservacoes) {
              setOpenModalObservacoes(true);
              return;
            }

            const { latitude, longitude } = await buscaCoordenadasAtuais();

            console.log('registrar devolução', val);
            dispatch(entregaActions.registraNovoGestaoEntrega({
              gestao: {
                ...val,
                latitude,
                longitude,
              },
              carga: carga as Carga
            }))
          }}
        >
          {({ values, handleSubmit }) => (
            <Form>
              <InputField
                label="Nome do Recebedor"
                placeholder="Nome do Recebedor"
                name="nome_recebedor"
              />
              {/* <InputField
                label="Documento do Recebedor (RG ou CPF)"
                placeholder="Documento do Recebedor (RG ou CPF)"
                name="rg_recebedor"
                type="tel"
              /> */}
              <SelectField
                label="Motivo da Devolução"
                name="motivo_devolucao"
              >
                {getMotivosNaoEntrega().map(motivo => (
                  <option key={`${motivo.value}`} value={motivo.value}>{motivo.name}</option>
                ))}
              </SelectField>

              {values.motivo_devolucao == '4' && <RadioField mt={2} name="e_tentativa_entrega">
                <Stack mx="auto" direction="column" bg="white" p={4} borderRadius={5}>
                  <Radio size="lg" value={0}>Não vou reentregar, finalizar.</Radio>
                  <Radio size="lg" value={1}>Vou reentregar depois.</Radio>
                </Stack>
              </RadioField>}

              <Images large={true} />

              <ModalObservacoes onSubmited={handleSubmit} onOpenModal={openModalObservacoes} onOpenedModal={() => setOpenedModalObservacoes(true)} />

              <BotaoFinalizarEntrega />
            </Form>
          )}
        </Formik>}
      </Flex>

    </Layout>
  )
}

export default RegistrarDevolucao;