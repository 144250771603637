import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Array } from "./types/array";

type TInitialState = {
  arrays: Array;
  arrays_updated_at?: Date;
};

const initialState: TInitialState = {
  arrays: {},
};

const reducer = createSlice({
  name: "array",
  initialState,
  reducers: {
    arraysRequest(state) {},
    arraysRequestSuccess(state, { payload }: PayloadAction<Array>) {
      state.arrays = payload;
      state.arrays_updated_at = new Date();
    },
  },
});

export const arrayActions = reducer.actions;

export default reducer.reducer;
