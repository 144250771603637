import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import loginReducer from "../login/reducer";
import cargaReducer from "../carga/reducer";
import agrupamentoReducer from "../agrupamento/reducer";
import entregaReducer from "../entrega/reducer";
import transbordoReducer from "../transbordo/reducer";
import imagensRejeitadasReducer from "../imagens-rejeitadas/reducer";
import arrayReducer from "../array/reducer";
import { mainSaga } from "./mainSaga";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const sagaMiddleware = createSagaMiddleware();
const logger = createLogger();
const persistConfig = {
  key: "gestao_de_entrega",
  storage,
};
const rootReducer = combineReducers({
  login: loginReducer,
  carga: cargaReducer,
  agrupamento: agrupamentoReducer,
  entrega: entregaReducer,
  transbordo: transbordoReducer,
  imagensRejeitadas: imagensRejeitadasReducer,
  array: arrayReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

let middlewares: any = [sagaMiddleware];
if (process.env.NODE_ENV === "development") {
  middlewares = [...middlewares, logger];
}

export const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
});
export const persistor = persistStore(store);

sagaMiddleware.run(mainSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
