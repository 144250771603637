import { all, takeLatest, call, put } from "redux-saga/effects";
import { arrayActions } from "./reducer";
import { apiCall } from "../../utils/apiCall";
import { AxiosResponse } from "axios";
import { Array } from "./types/array";

function* arraysRequestWorker() {
  try {
    const res: AxiosResponse<Array> = yield call(apiCall, {
      url: `/gestao-de-entrega/v2/arrays`,
      method: "GET",
    });

    yield put(arrayActions.arraysRequestSuccess(res.data));
  } catch (error) {
    console.log('error arraysRequestWorker', error);
  }
}

export function* arraySaga() {
  yield all([
    takeLatest(arrayActions.arraysRequest.type, arraysRequestWorker),
  ]);
}
