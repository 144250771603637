import config from "../../../utils/config";
import { store } from "../../app/mainReducer";

const getMotivosNaoEntrega = () => {
  if (config.motivosDevolucaoPelaApi) {
    const array = store.getState().array?.arrays['motivo_devolucao'];

    if (array && array.length > 0) {
      return array;
    }
  }

  return [
    { value: 1, name: "Nenhum" },
    { value: 2, name: "Não pediu" },
    { value: 3, name: "Desistiu da compra" },
    { value: 4, name: "Cliente ausente" },
    { value: 5, name: "Endereço não encontrado" },
    { value: 8, name: "Avarias Embalagens" },
    { value: 9, name: "Farinha Empedrada" },
    { value: 10, name: "Molhado" },
    { value: 11, name: "Vencido" },
    { value: 12, name: "Perto do Vencimento" },
  ]
}

export default getMotivosNaoEntrega;