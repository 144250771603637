import { all } from "redux-saga/effects";
import { cargaSaga } from "../carga/saga";
import { entregaSaga } from "../entrega/saga";
import { loginSaga } from "../login/saga";
import { transbordoSaga } from "../transbordo/saga";
import { imagensRejeitadasSaga } from "../imagens-rejeitadas/saga";
import { agrupamentoSaga } from "../agrupamento/saga";
import { arraySaga } from "../array/saga";

export function* mainSaga() {
  yield all([
    loginSaga(),
    cargaSaga(),
    agrupamentoSaga(),
    entregaSaga(),
    transbordoSaga(),
    imagensRejeitadasSaga(),
    arraySaga(),
  ]);
}
